import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import tw, { styled } from 'twin.macro'

const Column = styled.div`
  & a {
    ${tw`block pb-2 md:py-0 `}
  }
`
const FooterStyle = styled.footer`
  label {
    ${tw`block md:hidden border-t-2 border-b-2 border-navy font-bold text-lg py-2 mb-4`}
  }
  @media (max-width: 768px) {
    #sitemap ~ .fColumn {
      ${tw`hidden`}
    }
    #sitemap:checked ~ .fColumn {
      ${tw`block`}
    }
  }
`

export default function Footer() {
  const data = useStaticQuery(graphql`
    query {
      craftFooterGlobalSet {
        column1
        column2
        column3
        address
        bild {
          ... on Craft_productImages_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 250, layout: FIXED)
              }
            }
          }
        }
      }
    }
  `)
  return (
    <FooterStyle
      className="mm-px-dynamic mm-py-dynamic"
      css={tw`text-base bg-sand md:flex justify-between pb-5`}
    >
      <section css={tw`md:flex md:flex-wrap max-w-3xl`}>
        <div css={tw`mb-10 md:mb-0 md:w-2/5 md:mr-10`}>
          <Column
            dangerouslySetInnerHTML={{
              __html: data.craftFooterGlobalSet.column1,
            }}
          />
        </div>
        <label htmlFor="sitemap">
          Link oversigt
          <div css={tw`float-right mr-2`}>+</div>
        </label>

        <input type="checkbox" id="sitemap" css={tw`hidden`} />
        <div css={tw`mb-10 md:mb-0 md:w-1/5 md:mr-10`} className="fColumn">
          <Column
            dangerouslySetInnerHTML={{
              __html: data.craftFooterGlobalSet.column2,
            }}
          />
        </div>
        <div css={tw`mb-10 md:mb-0 md:w-1/5 md:mr-10`} className="fColumn">
          <Column
            dangerouslySetInnerHTML={{
              __html: data.craftFooterGlobalSet.column3,
            }}
          />
        </div>
        <Column
          css={tw`mb-10 md:mb-0 w-full mt-10 text-sm`}
          dangerouslySetInnerHTML={{
            __html: data.craftFooterGlobalSet.address,
          }}
        />
      </section>
      <section
        css={tw`text-center hidden md:flex md:flex-col justify-between relative`}
      >
        <div css={tw`mb-10 md:mb-0 text-center w-64`}>
          <div>
            {data.craftFooterGlobalSet?.bild[0]?.localFile?.childImageSharp
              ?.gatsbyImageData && (
              <GatsbyImage
                image={
                  data.craftFooterGlobalSet?.bild[0]?.localFile?.childImageSharp
                    ?.gatsbyImageData
                }
                css={tw`absolute w-full h-full left-0 top-0 -mt-10`}
                alt="Kort over forhandlere"
              />
            )}
            <div css={tw`z-10 relative`}>
              <h5 css={tw`pt-0 md:pt-20 pb-4 xl:pb-4`}>
                Find din STM forhandler{' '}
              </h5>
              <Link
                to="/kontakt/forhandlere"
                css={tw`bg-navy py-2 px-5 font-bold text-white uppercase rounded-full whitespace-nowrap`}
              >
                Find forhandlere
              </Link>
            </div>
          </div>
        </div>
        <div>
          <a
            href="https://www.linkedin.com/company/stm-vinduer-a-s/"
            rel="noreferrer"
            target="_blank"
            aria-label="LinkedIn"
          >
            <svg alt="" width="38" height="38" css={tw`inline  ml-3`}>
              <path
                id="Path_2506"
                data-name="Path 2506"
                d="M12.319,13.628c.119,0,.238.008.355,0a2.1,2.1,0,0,0,1.962-2.2,2.071,2.071,0,0,0-1.463-1.907,2.519,2.519,0,0,0-2.671.68,2.114,2.114,0,0,0,1.818,3.43Zm-2,14.049h4.011v-12.3H10.316Zm18.817,0c.012-.119.028-.221.028-.322,0-2.4.031-4.8-.033-7.2a7.482,7.482,0,0,0-.477-2.377c-1.2-3.186-5.286-3.32-6.961-1.759-.318.3-.6.634-.945,1V15.393H16.679v12.28h4.077v-7.1a2.225,2.225,0,0,1,1.076-1.953,1.994,1.994,0,0,1,3.1,1.188,6.5,6.5,0,0,1,.162,1.487c.018,1.978.008,3.956.008,5.935v.445ZM.841,19.1A18.156,18.156,0,1,1,18.2,37.279,18.148,18.148,0,0,1,.841,19.1Z"
                transform="translate(-0.841 -0.999)"
                fill="#283538"
                fillRule="evenodd"
              />
            </svg>
          </a>
          {/* <a
            href="https://stmvinduer.se"
            rel="noreferrer"
            target="_blank"
            aria-label="Facebook"
          >
            <svg alt="" width="38" height="38" css={tw`inline  ml-3`}>
              <path
                id="Path_2507"
                data-name="Path 2507"
                d="M13.874,15.755v3.409h2.043V28.97h4.045V19.15h1.932c.749,0,.764,0,.856-.726.111-.871.186-1.746.284-2.673H20c0-.8-.016-1.546.007-2.292a.725.725,0,0,1,.673-.714,4.259,4.259,0,0,1,.665-.063c.549-.01,1.1,0,1.647,0v-3.4c-.916,0-1.8-.007-2.681,0a10.444,10.444,0,0,0-1.064.071,3.332,3.332,0,0,0-3.194,2.884A15.411,15.411,0,0,0,15.9,14.4c-.021.442,0,.886,0,1.351ZM18.472.994A18.149,18.149,0,1,1,.3,19.089,18.192,18.192,0,0,1,18.472.994Z"
                transform="translate(-0.301 -0.993)"
                fill="#283538"
                fillRule="evenodd"
              />
            </svg>
          </a> */}
          {/* <a
            href="https://stmvinduer.se"
            rel="noreferrer"
            target="_blank"
            aria-label="Instagram"
          >
            <svg alt="" width="38" height="38" css={tw`inline  ml-3`}>
              <path
                id="Path_2520"
                data-name="Path 2520"
                d="M18.365,28.358q3.579,0,7.159,0a1.963,1.963,0,0,0,2.082-2.041q.012-7.16,0-14.319a1.979,1.979,0,0,0-2.072-2.064q-7.14-.007-14.28,0a1.969,1.969,0,0,0-2.071,2.092q0,7.12,0,14.24a1.947,1.947,0,0,0,2.1,2.092q3.541,0,7.081,0M18.429.994A18.154,18.154,0,1,1,.241,19.033,18.136,18.136,0,0,1,18.429.994"
                transform="translate(-0.241 -0.993)"
                fill="#283538"
                fillRule="evenodd"
              />
              <path
                id="Path_2521"
                data-name="Path 2521"
                d="M.859.956H2.61a5.706,5.706,0,0,0,1.48,5.821A5.559,5.559,0,0,0,8.509,8.4,5.792,5.792,0,0,0,13.589.96c.12-.007.238-.016.353-.016.447,0,.893,0,1.394,0V1.4q0,3.757,0,7.512c0,.761-.238,1-.982,1q-6.269,0-12.534,0c-.734,0-.962-.229-.962-.97V.956Z"
                transform="translate(10.06 15.471)"
                fill="#283538"
                fillRule="evenodd"
              />
              <path
                id="Path_2522"
                data-name="Path 2522"
                d="M.976,4.45A3.746,3.746,0,0,1,4.729.723,3.785,3.785,0,0,1,8.468,4.485a3.757,3.757,0,0,1-3.793,3.73A3.711,3.711,0,0,1,.976,4.45"
                transform="translate(13.436 13.679)"
                fill="#283538"
                fillRule="evenodd"
              />
              <path
                id="Path_2523"
                data-name="Path 2523"
                d="M2.289,3.56c-.277,0-.553.007-.83,0a.707.707,0,0,1-.789-.7A17.962,17.962,0,0,1,.663,1.042.744.744,0,0,1,1.481.313Q2.29.3,3.1.313a.767.767,0,0,1,.823.808c.015.553.016,1.105,0,1.658a.736.736,0,0,1-.8.775c-.276,0-.551,0-.828,0Z"
                transform="translate(21.469 10.11)"
                fill="#283538"
                fillRule="evenodd"
              />
            </svg>
          </a> */}
          <a
            href="https://www.youtube.com/channel/UCMRr_xFuBcIOwvYn90EWKhw"
            rel="noreferrer"
            target="_blank"
            aria-label="YouTube"
          >
            <svg alt="" width="38" height="38" css={tw`inline  ml-3`}>
              <path
                id="Path_2508"
                data-name="Path 2508"
                d="M18.974,37.287A18.7,18.7,0,0,0,37.947,18.644,18.8,18.8,0,0,0,18.974,0,18.909,18.909,0,0,0,0,18.644,18.8,18.8,0,0,0,18.974,37.287Z"
                fill="#283538"
              />
              <path
                id="Path_2509"
                data-name="Path 2509"
                d="M12.82,18.759a1.5,1.5,0,0,0,1.155.495c.66,0,1.155-.33,1.155-.99V14.47a1.014,1.014,0,0,0-1.485-.99,1.4,1.4,0,0,0-.825.495V11.5H11.5v7.919h1.32Z"
                transform="translate(7.474 7.473)"
                fill="none"
              />
              <path
                id="Path_2510"
                data-name="Path 2510"
                d="M15.667,18.588h.825c1.32,0,1.815-.99,1.65-2.145h-1.32v.825a.495.495,0,0,1-.99,0V15.784h2.475V13.969c0-.66-.33-.99-.99-1.32a1.822,1.822,0,0,0-1.32,0,1.754,1.754,0,0,0-1.485,1.65v2.97C14.347,17.928,14.842,18.423,15.667,18.588Z"
                transform="translate(9.411 8.141)"
                fill="none"
              />
              <path
                id="Path_2511"
                data-name="Path 2511"
                d="M9.655,18.475a1.5,1.5,0,0,0,1.155-.495.576.576,0,0,1,.33-.165v.66h1.32V12.7H10.975v3.96a.857.857,0,0,1-.33.66.606.606,0,0,1-.495.165.606.606,0,0,1-.165-.495V12.7H8.5v5.115A1.187,1.187,0,0,0,9.655,18.475Z"
                transform="translate(5.524 8.254)"
                fill="none"
              />
              <path
                id="Path_2512"
                data-name="Path 2512"
                d="M7.45,19.154H8.77V12.39h1.485V11.4H5.8v1.155H7.45Z"
                transform="translate(3.769 7.408)"
                fill="none"
              />
              <path
                id="Path_2513"
                data-name="Path 2513"
                d="M6.055,21.584a5.91,5.91,0,0,0,1.815.66,21.035,21.035,0,0,1,3.3.165h8.579a24.366,24.366,0,0,0,3.465-.165,2.559,2.559,0,0,0,2.475-1.485c.165-.33.165-.66.33-.99a40.2,40.2,0,0,0,.165-4.455,15.1,15.1,0,0,0-.165-2.64,2.238,2.238,0,0,0-1.155-1.815,3.326,3.326,0,0,0-1.815-.495c-2.97,0-5.94-.165-8.744-.165-2.145,0-4.29,0-6.27.165a2.909,2.909,0,0,0-2.475,1.32c-.33.33-.33.825-.495,1.32,0,.495-.165,1.155-.165,1.65v3.465a2.093,2.093,0,0,0,.165.99v.825A2.008,2.008,0,0,0,6.055,21.584Zm16-7.589h1.32a1.255,1.255,0,0,1,.99,1.32v1.815H21.894v1.485a.495.495,0,1,0,.99,0v-.825H24.2c.165,1.32-.33,2.145-1.65,2.145h-.825a1.445,1.445,0,0,1-1.155-1.485V15.48A1.741,1.741,0,0,1,22.059,13.995Zm-6.1-1.815h1.32v2.475a3.606,3.606,0,0,0,.825-.495,1.137,1.137,0,0,1,1.485.99v3.795c0,.66-.495.99-1.155.99a1.5,1.5,0,0,1-1.155-.495v.495h-1.32ZM10.84,14.49v-.33h1.32v4.29a.606.606,0,0,0,.165.495c.165.165.33,0,.495-.165.33-.165.33-.33.33-.66V14.16h1.32v5.775H13.314v-.66c-.165.165-.165.165-.33.165a1.5,1.5,0,0,1-1.155.495.924.924,0,0,1-.99-.825V14.49ZM6.385,12.18H10.84v.99H9.355v6.764H8.035V13.17H6.385Z"
                transform="translate(3.184 6.629)"
                fill="#fefefe"
              />
              <path
                id="Path_2514"
                data-name="Path 2514"
                d="M11.512,10.725a.437.437,0,0,0,.495-.495V7.095a.437.437,0,0,0-.495-.495.437.437,0,0,0-.495.495V10.23C10.852,10.56,11.182,10.725,11.512,10.725Z"
                transform="translate(7.131 4.29)"
                fill="none"
              />
              <path
                id="Path_2515"
                data-name="Path 2515"
                d="M10.1,10.565a1.761,1.761,0,0,0,1.155,1.485,2.808,2.808,0,0,0,1.155.165,1.517,1.517,0,0,0,1.485-1.65V7.595a1.255,1.255,0,0,0-.99-1.32,2.733,2.733,0,0,0-1.98,0,1.306,1.306,0,0,0-.825,1.32V9.08h0Q10.1,9.575,10.1,10.565Zm1.32-1.65V7.43a.437.437,0,0,1,.495-.495.437.437,0,0,1,.495.495v3.135a.437.437,0,0,1-.495.495.437.437,0,0,1-.495-.495Z"
                transform="translate(6.564 3.954)"
                fill="#fefefe"
              />
              <path
                id="Path_2516"
                data-name="Path 2516"
                d="M10.335,12.719V9.255c.33-.99.66-1.815.99-2.8a6.9,6.9,0,0,1,.66-1.65h-1.32c-.165,0-.165,0-.33.165a16.672,16.672,0,0,1-.495,2.8v.165L9.675,7.77c-.33-.99-.66-1.815-.99-2.8A.162.162,0,0,0,8.52,4.8H7.2v.165a33.055,33.055,0,0,0,1.65,4.29c0,.165.165.33.165.495v2.8h1.32Z"
                transform="translate(4.679 3.12)"
                fill="#fefefe"
              />
              <path
                id="Path_2517"
                data-name="Path 2517"
                d="M14.42,6.1H13.1v5.115c.165.33.33.66.825.66a2.661,2.661,0,0,0,1.485-.495.162.162,0,0,0,.165-.165v.66h1.32V6.1h-1.32v4.29a.857.857,0,0,1-.66.33c-.33.165-.495.165-.495-.165V6.1Z"
                transform="translate(8.513 3.964)"
                fill="#fefefe"
              />
              <path
                id="Path_2518"
                data-name="Path 2518"
                d="M12.3,17.047a.726.726,0,0,0,.825.165c.165,0,.33-.165.33-.495v-2.97c0-.495-.495-.825-.825-.495-.165,0-.165.165-.165.33v3.3C12.3,16.882,12.3,16.882,12.3,17.047Z"
                transform="translate(7.993 8.526)"
                fill="#fefefe"
              />
              <path
                id="Path_2519"
                data-name="Path 2519"
                d="M16.29,13.53c0-.33-.165-.33-.495-.33s-.495.165-.495.33v.825h1.32A1.066,1.066,0,0,1,16.29,13.53Z"
                transform="translate(9.943 8.578)"
                fill="#fefefe"
              />
            </svg>
          </a>
        </div>
      </section>
    </FooterStyle>
  )
}
